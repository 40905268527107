@media print {
  @page {
    size: A4 portrait;
    margin: 0mm;
    padding: 9mm;
  }
  .pages {
    margin: 0mm;
    padding: 0mm;
  }
  .labels {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 0 3mm;
    width: 192mm;
    margin: 0;
    padding: 9mm;
    break-inside: avoid;
    &__label {
      position: relative;
      width: 62mm;
      height: 31mm;
      &__shippingAddress {
        position: absolute;
        top: 2.4mm;
        left: 2.4mm;
        width: 100mm;
        overflow-wrap: break-word;
        word-break: keep-all;
        font-size: 2.6mm;
        transform: scale(0.8);
        transform-origin: top left;
        line-height: 3.2mm;
      }
      &__returnAddress {
        position: absolute;
        bottom: 2.4mm;
        left: 2.4mm;
        font-size: 2.6mm;
        transform: scale(0.8);
        transform-origin: bottom left;
        line-height: 3.2mm;
      }
      &__logo {
        position: absolute;
        top: 3mm;
        right: 2.4mm;
        & > img {
          height: 10mm;
          max-width: 20mm;
          object-fit: contain;
          object-position: right top;
        }
      }
      &__qr {
        position: absolute;
        bottom: 2.8mm;
        right: 2.4mm;
        &__desc {
          font-size: 2.6mm;
          transform: scale(0.8);
          line-height: 3.2mm;
        }
        & > svg {
          margin: 0.5mm auto;
          display: block;
        }
      }
    }
  }
}
