@media print {
  @page {
    size: A4 portrait;
    margin: 0mm;
    padding: 9mm;
  }
  .pages {
    margin: 0mm;
    padding: 0mm;
  }
  .labels {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 0 3mm;
    width: 192mm;
    margin: 0;
    padding: 9mm;
    break-inside: avoid;
    &__label {
      position: relative;
      width: 62mm;
      height: 31mm;
      &__shippingAddress {
        position: absolute;
        top: 2.4mm;
        left: 2.4mm;
        width: 100mm;
        overflow-wrap: break-word;
        word-break: keep-all;
        font-size: 2.6mm;
        transform: scale(0.7);
        transform-origin: top left;
        line-height: 3.6mm;
        &__title {
          font-weight: 600;
          font-size: 3.2mm;
          margin: 0 0 1mm 0;
          padding: 0;
        }
        &__postalcode,
        &__name {
          font-size: 3.2mm;
          margin: 0;
          padding: 0;
        }
        &__address {
          font-size: 3mm;
          margin: 0;
          padding: 0;
        }
      }
      &__returnAddress {
        position: absolute;
        bottom: 2.4mm;
        left: 2.4mm;
        font-size: 2.8mm;
        transform: scale(0.7);
        transform-origin: bottom left;
        line-height: 3.6mm;
        &__title {
          font-weight: 600;
          line-height: 3.2mm;
          margin: 0 0 1mm 0;
          padding: 0;
        }
      }
      &__desc {
        width: 34mm;
        height: 18mm;
        position: absolute;
        bottom: 2.4mm;
        right: 2.4mm;
        transform: scale(0.8);
        transform-origin: bottom right;
        &__item {
          display: flex;
          white-space: pre-wrap;
          font-weight: 600;
          transform: scale(0.6);
          transform-origin: top left;
          line-height: 3.2mm;
          width: 167%;
          margin-bottom: 1.5mm;
          &__title {
            margin: 0;
            padding: 0;
            font-size: 3.6mm;
          }
          &__content {
            margin: 0 0 0 3mm;
            padding: 0;
            font-size: 4mm;
          }
        }
        &__message {
          white-space: pre-wrap;
          font-size: 3.6mm;
          font-weight: 100;
          transform: scale(0.6);
          transform-origin: top left;
          line-height: 3.4mm;
          width: 167%;
          &__title {
            font-weight: 600;
            line-height: 3.6mm;
            font-size: 3.6mm;
            margin: 0 0 1mm 0;
            padding: 0;
          }
        }
      }
    }
  }
}
